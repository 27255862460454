1<template>
    <div class="body">
        <div class="imgs" v-if="$store.state.app.device === DEVICE_TYPE.MOBILE">
            <img style="width: 100%" src="./image/wxMobileLogin.jpg" alt="" srcset="" />
        </div>
        <div v-else class="login-box">
            <div class="login-info">
                <div class="login-info-top">
                    <div class="login-info-left">
                        <img src="./image/bgm4.png" alt="" />
                    </div>
                    <div class="login-info-right">
                        <div class="login-info-title">欢迎登录公文流转系统</div>
                        <a-form
                            id="formLogin"
                            class="user-layout-login"
                            ref="formLogin"
                            :form="form"
                            @submit="handleSubmit"
                        >
                            <a-form-item class="form-item-margin">
                                <a-input
                                    size="large"
                                    type="text"
                                    placeholder="请输入您的账号"
                                    v-decorator="[
                                        'account',
                                        { initialValue: '' },
                                        {
                                            rules: [
                                                { required: true, message: '请输入帐户名' },
                                                { validator: handleUsernameOrEmail },
                                            ],
                                            validateTrigger: 'change',
                                        },
                                    ]"
                                >
                                    <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
                                </a-input>
                            </a-form-item>

                            <a-form-item class="form-item-margin">
                                <a-input
                                    size="large"
                                    type="password"
                                    autocomplete="false"
                                    placeholder="请输入您的密码"
                                    v-decorator="[
                                        'password',
                                        { initialValue: '' },
                                        { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur' },
                                    ]"
                                >
                                    <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
                                </a-input>
                            </a-form-item>
                            <a-form-item class="form-item-margin1">
                                <a-button
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    class="login-button"
                                    :loading="state.loginBtn"
                                    :disabled="state.loginBtn"
                                    block
                                    >登录</a-button
                                >
                            </a-form-item>
                        </a-form>
                    </div>
                </div>

                <div class="login-info-bottom">
                    <div class="login-info-tool"><span>帮助</span><span>隐私</span><span>条款</span></div>
                    <span class="login-info-web">Copyright 2022 贵州遐宇科技服务有限公司 <a href="https://beian.miit.gov.cn/">黔ICP备20006285号-1</a> All rights reserved. </span>
                </div>
            </div>
        </div>
    </div>
</template>
 


<script>
import { DEVICE_TYPE } from '@/utils/device'
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            DEVICE_TYPE,
            form: this.$form.createForm(this),
            state: {
                time: 60,
                loginBtn: false,
                // login type: 0 email, 1 username, 2 telephone
                loginType: 0,
                smsSendBtn: false,
                loginParams: [], // 登录参数
            },
        }
    },
    created() {},
    methods: {
        ...mapActions(['Login', 'Logout', 'dictTypeData']),
        handleUsernameOrEmail(rule, value, callback) {
            const { state } = this
            const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
            if (regex.test(value)) {
                state.loginType = 0
            } else {
                state.loginType = 1
            }
            callback()
        },
        handleSubmit(e) {
            e.preventDefault()
            const {
                form: { validateFields },
                state,
                customActiveKey,
                Login,
            } = this

            state.loginBtn = true
            const validateFieldsKey = ['account', 'password']
            validateFields(validateFieldsKey, { force: true }, (err, values) => {
                this.loginParams = values
                if (!err) {
                    const loginParams = { ...values }
                    delete loginParams.account
                    loginParams.account = values.account
                    Login(loginParams)
                        .then((res) => this.loginSuccess(res))
                        .catch((err) => this.requestFailed(err))
                        .finally(() => {
                            state.loginBtn = false
                        })
                } else {
                    setTimeout(() => {
                        state.loginBtn = false
                    }, 600)
                }
            })
        },
        loginSuccess(res) {
            // console.log(this.$store.state.app.device)
            if (this.$store.state.app.device === DEVICE_TYPE.MOBILE) {
                this.$router.push({ path: '/mobile/home' })
            } else {
                this.$router.push({ path: '/fileStreamHome' })
            }
            this.isLoginError = false
            // 加载字典所有字典到缓存中
            this.dictTypeData().then((res) => {})
        },
        requestFailed(err) {
            this.accountLoginErrMsg = err
            this.isLoginError = true
        },
    },
}
</script>
<style lang="less" scoped>
#userLayout.user-layout-wrapper .container {
    background: none;
}
.body {
    width: 100%;
    height: 100%;
    background-color: #333333;
    .imgs {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        display: flex;
        align-items: flex-start;
    }
}
div,
img,
span {
    box-sizing: border-box;
}
.form-item-margin {
    margin: 30px 0;
}
.form-item-margin1 {
    margin: 50px 0;
}
.login-box {
    width: 100%;
    height: 100%;
    background-color: aqua;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('./image/bgm1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.login-info {
    width: 1200px;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-image: url('./image/bgm5.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    .login-info-title {
        text-align: center;
        font-size: 32px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 30px;
    }
    .user-layout-login {
        flex: 1;
        width: 80%;
        margin: 0 auto;
        ::v-deep .ant-input {
            border-radius: 35px;
        }
    }
}

.login-info-top {
    display: flex;
    width: 100%;
    height: 100%;
}

.login-info-left {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.login-info-left img {
    width: 493px;
    height: 430px;
    margin-top: 80px;
}

/**  右边  */
.login-info-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // align-items: flex-start;
    justify-content: space-between;
    // padding: 80px 0 100px 80px;
    padding: 160px 80px 100px;
}
.login-info-right .login-info-wellocome {
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0067ff;
    display: flex;
    flex-direction: column;
}
.login-info-right .login-info-line {
    width: 80px;
    height: 2px;
    background: #0067ff;
    margin-top: 30px;
}

.login-info-right .login-info-wxlogin-box {
    width: 100%;
    height: 113px;
    position: relative;
}
.login-info-right .login-info-tap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px 20px 0;
    color: #ccc;
}
.login-info-right .login-info-wxlogin {
    font-size: 40px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ffffff;
    width: 490px;
    height: 113px;
    background-image: url('./image/bgm6.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    position: absolute;
    right: -12px;
    z-index: 2;
    cursor: pointer;
}
.login-info-right .login-info-wxlogin span {
    width: 33px;
    height: 24px;
    display: inline-block;
    background-image: url('./image/bgm7.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-left: 47px;
}

/**  底部网站说明  */
.login-info-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}
.login-info-bottom .login-info-tool {
    font-size: 18px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ccc;
    line-height: 36px;
}
.login-info-bottom span {
    cursor: pointer;
}
.login-info-bottom .login-info-tool > span:nth-child(2) {
    padding: 0 30px;
}
.login-info-bottom .login-info-web {
    font-size: 18px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ccc;
    line-height: 36px;
}
</style>